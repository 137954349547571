(function(window) {
  if (!window) {
    return;
  }
  var requestAnimFrame = (function() {
    return (
      window.requestAnimationFrame ||
      window.webkitRequestAnimationFrame ||
      window.mozRequestAnimationFrame ||
      function(callback) {
        window.setTimeout(callback, 1000 / 60);
      }
    );
  })();

  var easeInOutQuad = function(t, b, c, d) {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };

  var animatedScrollTo = function(element, to, duration, callback) {
    var start = element.scrollTop,
      change = to - start,
      animationStart = +new Date();

    var animating = true;
    var lastpos = null;

    var animateScroll = function() {
      if (!animating) {
        return;
      }
      requestAnimFrame(animateScroll);
      var now = +new Date();
      var val = Math.floor(
        easeInOutQuad(now - animationStart, start, change, duration),
      );

      lastpos = val;
      element.scrollTop = val;
      if (now > animationStart + duration) {
        element.scrollTop = to;
        animating = false;
        if (callback) {
          callback();
        }
      }
    };
    requestAnimFrame(animateScroll);
  };

  if (typeof module !== "undefined" && typeof module.exports !== "undefined") {
    module.exports = animatedScrollTo;
  } else {
    window.animatedScrollTo = animatedScrollTo;
  }
})(typeof window === "undefined" ? undefined : window);
