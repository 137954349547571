import React, { useMemo } from "react";
import C from "classnames";

import { ITherapy, ITherapyOption } from "shared/lib/models/therapy";
import { IConfiguration } from "shared/lib/models/configuration";

import ConfigurationRadio from "../configurationRadio";
import TherapyCard from "../therapyCard";

import style from "./index.module.styl";
import { useGlobalStore } from "../../store";

interface IProps {
  therapies: Array<ITherapy>;
  configuration: IConfiguration;
  onInfoWidgetClick: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    therapy: ITherapy,
  ) => void;
  onChooseTherapy: (therapy: ITherapy) => void;
  onChooseTherapyOption: (option: ITherapyOption, therapy: ITherapy) => void;
  contentClassName?: string;
}

const getAvailableOptions = (
  therapy: ITherapy,
  configuration: IConfiguration,
): ITherapyOption[] => {
  const availableOptions =
    therapy.options?.filter((option: ITherapyOption) => {
      if (
        !option.availableWithTherapyIds ||
        option.availableWithTherapyIds.length === 0
      )
        return true;
      const allIsGood = option.availableWithTherapyIds.reduce(
        (allIsGood, therapyId) => {
          if (!allIsGood) return false;
          if (therapy.id === therapyId) return true;
          if (configuration.therapy?.id === therapyId) return true;
          if (configuration.optionalTherapies?.some(t => t.id === therapyId))
            return true;
          return false;
        },
        true,
      );
      return allIsGood;
    }) || [];
  return availableOptions;
};

const TherapyOptionPanel = ({
  therapies,
  configuration,
  onInfoWidgetClick,
  onChooseTherapy,
  onChooseTherapyOption,
  contentClassName,
}: IProps) => {
  const { i18n } = useGlobalStore();
  const optionalTherapies = useMemo(() => {
    return therapies
      .filter(therapy => !therapy.isBaseTherapy)
      .map((therapy: ITherapy) => {
        const availableOptions = getAvailableOptions(therapy, configuration);
        return {
          ...therapy,
          price: therapy.price
            ? therapy.price
            : availableOptions[0]?.price || 0,
          options: configuration.optionalTherapies.some(
            t => t.id === therapy.id,
          )
            ? availableOptions
            : [],
        };
      });
  }, [therapies]);

  const {
    therapyHelpers: { getTherapyLabel, getTherapyOptionLabel },
  } = useGlobalStore();

  return (
    <div style={{ width: "100%" }}>
      <div
        className={C(style.therapyOptionPanel, {
          [contentClassName || ""]: !!contentClassName,
        })}
      >
        <div className={style.therapyBaseOptions}>
          {therapies
            .filter(therapy => therapy.isBaseTherapy)
            .map((therapy, index) => (
              <ConfigurationRadio
                key={`therapy-pick-${therapy.id}`}
                name="therapy"
                label={getTherapyLabel(therapy, i18n)}
                labelDescription={therapy.shortDescription}
                onInfoWidgetClick={e => onInfoWidgetClick(e, therapy)}
                infoWidget={
                  <TherapyCard therapy={therapy} showCollectionButton={false} />
                }
                checked={
                  configuration.therapy
                    ? configuration.therapy.id === therapy.id
                    : index === 0
                }
                onClick={() => onChooseTherapy(therapy)}
              />
            ))}
        </div>
        <div className={style.therapySecondaryOptions}>
          {optionalTherapies.length > 0 && (
            <p className={style.therapySecondaryOptionsTitle}>More options</p>
          )}
          {optionalTherapies.map((therapy: ITherapy) => {
            const isIncluded =
              !!therapy.includedWithTherapyId &&
              therapy.includedWithTherapyId === configuration.therapy.id;

            const therapyOptions = !!therapy.options
              ? therapy.options.filter(
                  (_option, index) => !(configuration.therapy && index === 0),
                )
              : [];

            return (
              <React.Fragment key={`optional-therapy-pick-${therapy.id}`}>
                <ConfigurationRadio
                  name="optional-therapy"
                  label={getTherapyLabel({ ...therapy, options: [] }, i18n)}
                  labelDescription={therapy.shortDescription}
                  price={therapy.price}
                  isIncluded={isIncluded}
                  onInfoWidgetClick={e => onInfoWidgetClick(e, therapy)}
                  infoWidget={
                    <TherapyCard
                      therapy={therapy}
                      showCollectionButton={false}
                    />
                  }
                  onClick={() => {
                    if (!isIncluded) onChooseTherapy(therapy);
                  }}
                  isCheckbox
                  isCheckboxChecked={
                    !!configuration.optionalTherapies?.find(
                      optionalTherapy => optionalTherapy.id === therapy.id,
                    )
                  }
                  className={C(style.optionalTherapy, {
                    [style.optionalTherapyWithOption]:
                      therapyOptions.length > 0,
                  })}
                />
                {therapyOptions.map(option => (
                  <div
                    key={`optional-therapy-${option.id}`}
                    className={style.optionalTherapyOptionContainer}
                  >
                    <ConfigurationRadio
                      name="optional-therapy"
                      label={getTherapyOptionLabel(option, i18n)}
                      price={option.price}
                      onClick={() => onChooseTherapyOption(option, therapy)}
                      isAddIcon
                      isCheckbox
                      isCheckboxChecked={
                        !!configuration.optionalTherapies
                          ?.find(t => t.id === therapy.id)
                          ?.options?.some(o => o.id === option.id)
                      }
                      className={style.optionalTherapyOption}
                    />
                  </div>
                ))}
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default TherapyOptionPanel;
