import React, { useState } from "react";

import { IOption, IOptionGroup, IOptionSet } from "shared/src/models/model";

import Checkbox from "../forms/checkbox";
import BaseOptionPanel from "../baseOptionPanel";

import style from "./index.module.styl";

interface IProps {
  label: string;
  onCheckToggle: (checked: boolean, defaultOption: IOption) => void;
  optionGroup: IOptionGroup | undefined;
  isOptionDisplayed: boolean;
  beforeBannerText?: string;
  subBannerTitle?: string;
  subBannerText?: string;
  name: string;
  imageCallback: (option: IOption) => any;
  banner?: any;
  chosenOption?: IOption;
  onChoose: (option: IOption) => void;
  footnote?: string;
  optionSets?: Array<IOptionSet>;
}

const OptionalOptionPanel = ({
  label,
  onCheckToggle,
  optionGroup,
  isOptionDisplayed,
  beforeBannerText,
  subBannerTitle,
  subBannerText,
  name,
  imageCallback,
  banner,
  chosenOption,
  onChoose,
  footnote,
  optionSets,
}: IProps) => {
  const [isDisplayed, setIsDisplayed] = useState<boolean>(isOptionDisplayed);

  const onToggle = () => {
    onCheckToggle(!isDisplayed, optionGroup?.options[0] || ({} as IOption));
    setIsDisplayed((value: boolean) => !value);
  };

  return (
    <>
      <BaseOptionPanel
        optionGroup={optionGroup}
        beforeBannerText={beforeBannerText}
        subBannerTitle={isDisplayed ? subBannerTitle : undefined}
        subBannerText={isDisplayed ? subBannerText : undefined}
        subBannerContent={
          <label
            tabIndex={0}
            role={"button"}
            onClick={() => onToggle()}
            onKeyPress={() => onToggle()}
            className={style.configurationCheckboxContainer}
          >
            <Checkbox tabIndex={-1} checked={isDisplayed} isAddIcon />
            <span className={style.configurationCheckboxLabel}>{label}</span>
          </label>
        }
        name={name}
        imageCallback={imageCallback}
        banner={banner}
        chosenOption={chosenOption}
        onChoose={onChoose}
        footnote={footnote}
        optionsDisplayed={isDisplayed}
        optionSets={optionSets}
      />
    </>
  );
};

export default OptionalOptionPanel;
