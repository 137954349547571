import React from "react";
import { useGlobalStore } from "../../store";
import { ILocale } from "shared/lib/locales/base";

interface ITextProps {
  readonly t: keyof ILocale;
  readonly params?: any;
}

interface IUnsafeTextProps {
  readonly t: string;
  readonly params?: any;
}

export default function Text({ t, params }: ITextProps) {
  const { i18n } = useGlobalStore();

  return <>{i18n.t(t, params)}</>;
}

export function UnsafeText({ t, params }: IUnsafeTextProps) {
  const { i18n } = useGlobalStore();
  return <>{i18n.unsafeT(t, params)}</>;
}
