import React from "react";

import style from "./index.module.styl";

interface IProps {
  label: string;
  value: string;
  price?: number;
  onClick: () => void;
}

const ConfigurationOption = ({ label, value, price, onClick }: IProps) => (
  <div
    onClick={() => onClick()}
    onKeyPress={() => onClick()}
    tabIndex={0}
    role={"button"}
    className={style.configurationOption}
  >
    <div className={style.configurationOptionInfosLeft}>
      <p
        className={style.configurationOptionLabel}
        dangerouslySetInnerHTML={{ __html: label }}
      />
      {!!value && (
        <p
          className={style.configurationSelectedOption}
          dangerouslySetInnerHTML={{ __html: value }}
        />
      )}
    </div>
    <div className={style.configurationOptionInfosRight}>
      {!!price && <p className={style.configurationOptionPrice}>$ {price}</p>}
      <img
        src={require("../../images/icons/arrow-down-black.svg")}
        alt="arrow"
        className={style.configurationOptionArrow}
      />
    </div>
  </div>
);

export default ConfigurationOption;
