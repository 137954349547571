import React from "react";
import { Link } from "gatsby";

import { ITherapy } from "../../models/therapy";
import { ROUTES } from "../../common/routes.js";
import { useGlobalStore } from "../../store/index";

import Markdown from "../markdown";
import Text from "../text";
import TherapyTitleImage from "../therapyTitleImage";
import C from "classnames";

import style from "./index.module.styl";
import { makeQueryLink } from "../../utils/routing";

interface ITherapyCardProps {
  readonly therapy: ITherapy;
  readonly showCollectionButton?: boolean;
  readonly fullWidth?: boolean;
  readonly noBackground?: boolean;
}

const TherapyCard = ({
  therapy,
  showCollectionButton = true,
  fullWidth = false,
  noBackground = false,
}: ITherapyCardProps) => {
  const { makeLink, i18n } = useGlobalStore();
  return (
    <div
      className={C(style.therapyCard, {
        [style.therapyCardFullWidth]: fullWidth,
        [style.therapyCardNoBackground]: noBackground,
      })}
    >
      <p className={style.therapyCategory}>
        {i18n.tObj(therapy.name, therapy.nameFr)}
      </p>
      <TherapyTitleImage therapy={therapy} />
      <p className={style.therapyDescription}>
        <Markdown
          source={i18n.tObj(
            therapy.categoryDescription,
            therapy.categoryDescriptionFr,
          )}
        />
      </p>
      <div className={style.therapyLinks}>
        <Link
          to={makeLink(ROUTES.therapy(therapy.name))}
          className={style.therapyLink}
        >
          <Text t="therapies_read_more_link" />
        </Link>
        {showCollectionButton && (
          <Link
            to={makeQueryLink(ROUTES.collections(), {
              therapies: therapy.id,
            })}
            className={style.therapyLink}
            state={{ therapyPreFilter: therapy.id }}
          >
            <Text t="therapies_collections_link" />
          </Link>
        )}
      </div>
    </div>
  );
};

export default TherapyCard;
