import React from "react";
import Img from "gatsby-image";
import C from "classnames";

import { IFluidImage } from "../../common/gatsby";
import { useGlobalStore } from "../../store";

import Checkbox from "../forms/checkbox";
import Radio from "../forms/radio";

import style from "./index.module.styl";

interface IProps {
  name: string;
  label: string;
  labelDescription?: string;
  price?: number;
  image?: IFluidImage;
  checked?: boolean;
  infoWidget?: React.ReactNode;
  onInfoWidgetClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onClick: () => void;
  isCheckbox?: boolean;
  isCheckboxChecked?: boolean;
  isBigImage?: boolean;
  id?: string;
  isIncluded?: boolean;
  className?: string;
  isAddIcon?: boolean;
}

const ConfigurationRadio = ({
  name,
  label,
  labelDescription,
  price,
  image,
  checked,
  onClick,
  onInfoWidgetClick,
  isCheckbox,
  isCheckboxChecked,
  isBigImage,
  id,
  isIncluded,
  className,
  isAddIcon,
}: IProps) => {
  const { i18n } = useGlobalStore();
  return (
    <div
      tabIndex={0}
      onClick={() => onClick()}
      onKeyPress={() => onClick()}
      role={"button"}
      className={C(style.configurationRadio, {
        [className || ""]: !!className,
        [style.configurationRadioBorderless]: isBigImage && !!image,
      })}
    >
      {isBigImage && !!image && (
        <label
          htmlFor={id}
          className={style.configurationRadioBigImageContainer}
        >
          <Img
            fluid={image.childImageSharp.fluid}
            alt={`${label} image`}
            imgStyle={{ objectFit: "cover" }}
            className={style.configurationRadioBigImage}
          />
        </label>
      )}
      <div className={style.configurationRadioContainer}>
        <label className={style.configurationRadioInput}>
          {!!image && !isBigImage && (
            <Img
              fluid={image.childImageSharp.fluid}
              alt={`${label} image`}
              className={style.configurationRadioImage}
            />
          )}
          {isCheckbox ? (
            <Checkbox
              id={id}
              tabIndex={-1}
              checked={!!isCheckboxChecked}
              className={style.configurationRadioControl}
              isAddIcon={isAddIcon}
              disabled={isIncluded}
            />
          ) : (
            <Radio
              id={id}
              name={name}
              tabIndex={-1}
              checked={checked}
              className={style.configurationRadioControl}
              disabled={isIncluded}
            />
          )}
          <div className={style.configurationRadioTexts}>
            <p className={style.configurationRadioText}>{label}</p>
            {!!labelDescription && (
              <p className={style.configurationRadioTextDescription}>
                {labelDescription}
              </p>
            )}
          </div>
        </label>
        <div className={style.configurationRadioInfos}>
          {!!onInfoWidgetClick && (
            <div
              onClick={onInfoWidgetClick}
              className={style.configurationRadioWidgetContainer}
            >
              <button
                type="button"
                className={style.configurationRadioWidgetIcon}
              >
                <img
                  src={require("../../images/icons/infos.svg")}
                  alt="option infos"
                />
              </button>
            </div>
          )}
          {!!price && !isIncluded && (
            <p className={style.configurationRadioPrice}>$ {price}</p>
          )}
          {isIncluded && (
            <p className={style.configurationRadioIncludedPrice}>
              {i18n.t("configuration_price_included")}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ConfigurationRadio;
