import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useLayoutEffect,
  useMemo,
} from "react";
import C from "classnames";
import { navigate } from "gatsby";

import { IColor, IModel, IOption, IOptionGroup } from "shared/lib/models/model";
import { ITherapy, ITherapyOption } from "shared/lib/models/therapy";
import { computeOrderPrice } from "shared/lib/utils/order";
import { computeTMUTherapyPrice } from "shared/lib/utils/therapy";
import { computeTherapiesPrice } from "shared/lib/utils/therapy";
import { stringIncludes } from "shared/lib/utils/string";
import {
  ConfigurationOptions,
  IConfiguration,
} from "shared/lib/models/configuration";
import {
  towelBarOptionSets,
  skirtTypes,
  wasteOverflowOptionSets,
} from "shared/lib/utils/option";

import { ROUTES } from "../../common/routes.js";
import * as animatedScrollTo from "../../utils/animatedScrollTo.js";
import { useGlobalStore } from "../../store";

import BaseOptionPanel from "../baseOptionPanel";
import ConfigurationActionSection from "../configurationActionSection";
import ConfigurationOption from "../configurationOption";
import OptionalOptionPanel from "../optionalOptionPanel";
import Text from "../text";
import TherapyCard from "../therapyCard";
import TherapyOptionPanel from "../therapyOptionPanel";

import style from "./index.module.styl";
import { sendAddToCartEvent, sendCustomizeProductEvent } from "../../common/analytics";

const TypeConditionalPanel = ({
  type,
  ...props
}: {
  type: any;
  [props: string]: any;
}) => React.createElement(type || BaseOptionPanel, props);

const getOptionGroups = (model: IModel) => {
  return model.options.reduce<IOptionGroup[]>((groups, option) => {
    const group = groups.find(group => group.name === option.optionGroup.name);

    return group
      ? groups.map(g =>
          g.name === group.name
            ? ({
                ...g,
                options: [...g.options, option],
                isRequired: !!group.options?.some(option => option.isRequired),
              } as IOptionGroup)
            : g,
        )
      : [
          ...groups,
          {
            name: option.optionGroup.name,
            options: [option],
            availableWithTherapyIds: option.optionGroup.availableWithTherapyIds,
            isRequired: !!option.optionGroup?.options?.find(
              option => option.isRequired,
            ),
          } as IOptionGroup,
        ];
  }, []);
};

const isOptionGroupAvailableWithTherapy = (
  optionGroup: IOptionGroup,
  therapy: ITherapy,
) => {
  const therapyIds = optionGroup?.availableWithTherapyIds || [];
  const therapyId = therapy?.id || -1;

  return therapyIds.length === 0 || therapyIds.includes(therapyId);
};

const isOptionGroupAvailableWithCurrentOptions = (
  optionGroup: IOptionGroup,
  configuration: IConfiguration,
): boolean => {
  return (
    optionGroup.options.filter(option => {
      if (!option.requiredOption) {
        return true;
      }
      return Object.values(configuration).some(
        configOption => configOption?.id === option.requiredOption.id,
      );
    }).length > 0
  );
};

interface IProps {
  model: IModel;
  collectionName: string;
}

const ConfigurationPanel = ({ collectionName, model }: IProps) => {
  const therapies: Array<ITherapy> =
    model.therapies
      ?.map(therapy =>
        therapy.isTMU && therapy.isBaseTherapy
          ? { ...therapy, price: computeTMUTherapyPrice(model) }
          : therapy,
      )
      .sort((a, b) => a.price - b.price) || [];

  const [price, setPrice] = useState<number>(model.tubPrice);
  const [openOption, setOpenOption] = useState<
    ConfigurationOptions | undefined
  >(undefined);
  const [nextOption, setNextOption] = useState<
    ConfigurationOptions | undefined
  >(undefined);
  const [configuration, setConfiguration] = useState<IConfiguration>(
    !!model.configuration
      ? model.configuration
      : (({
          therapy:
            therapies.length > 0
              ? therapies.find(therapy => therapy.isBaseTherapy)
              : undefined,
          optionalTherapies: [],
        } as unknown) as IConfiguration),
  );
  const [optionOpening, setOptionOpening] = useState(false);
  const [optionOpened, setOptionOpened] = useState(false);
  const [optionClosing, setOptionClosing] = useState(false);
  const overlayRef = useRef<HTMLDivElement>(null);
  const [currentPanelRef, setCurrentPanelRef] = useState<
    HTMLDivElement | undefined
  >(undefined);
  const optionsRef = useRef<HTMLDivElement>(null);
  const [maxHeight, setMaxHeight] = useState<number | undefined>(undefined);
  const didStartTimer = useRef(false);
  const contentWrapper = useRef(null);

  const [therapyOverlay, setTherapyOverlay] = useState<{
    therapy: ITherapy;
    arrowTop: number;
    overlayTop: number;
  } | null>(null);
  const isMounted = useRef(true);

  const {
    i18n,
    makeLink,
    optionHelpers: { getConfigurationOptionValue },
    therapyHelpers: { getChosenTherapiesLabel },
  } = useGlobalStore();

  const [optionGroups, setOptionGroups] = useState<Array<IOptionGroup>>([]);
  const [filteredOptionGroupsMap, setFilteredOptionGroupsMap] = useState<
    Map<ConfigurationOptions, IOptionGroup>
  >();
  const [filteredOptionGroups, setFilteredOptionGroups] = useState<
    Array<IOptionGroup>
  >([]);

  // Gets all the potentially available options for a model
  useEffect(() => setOptionGroups(getOptionGroups(model)), [model]);

  // 1. Filters the available option groups when a therapy is chosen
  // 2. Filters the available options of certain option groups when
  //    a bath color or a disposition is chosen.
  //    Ex. #1 : Only list white skirts when bath color is white
  //    Ex. #2 : Only list left tile flange if disposition is left
  useEffect(() => {
    setFilteredOptionGroups(
      optionGroups
        .filter(
          optionGroup =>
            isOptionGroupAvailableWithTherapy(
              optionGroup,
              configuration.therapy,
            ) &&
            isOptionGroupAvailableWithCurrentOptions(
              optionGroup,
              configuration,
            ),
        )
        .map(optionGroup => ({
          ...optionGroup,
          options: configuration.bathColor
            ? removeOptionRequiredOptions(optionGroup, [
                configuration.bathColor,
                configuration.disposition,
              ])
            : optionGroup.options,
        })),
    );
  }, [
    optionGroups,
    configuration.therapy?.id,
    configuration.bathColor,
    configuration.disposition,
  ]);

  // Filters options that have a required option and it does not
  // appear in the current configuration
  const removeOptionRequiredOptions = (
    optionGroup: IOptionGroup,
    filters: Array<IOption | undefined>,
  ) => {
    return optionGroup.options.filter(
      option =>
        option.requiredOption == undefined ||
        (!!option.requiredOption &&
          filters.some(filter => filter?.id === option.requiredOption.id)),
    );
  };

  // Builds a map representing filteredOptionGroups
  //
  // Reason a map is built is that a search in the list is made often
  // Using a map makes search in the list faster
  useEffect(() => {
    if (filteredOptionGroups && filteredOptionGroups.length > 1) {
      const map = new Map<ConfigurationOptions, IOptionGroup>();
      filteredOptionGroups.forEach(optionGroup => {
        const optionValue = Object.values(ConfigurationOptions).find(
          value => value === optionGroup.name,
        );
        if (optionValue) map.set(optionValue, optionGroup);
      });
      setFilteredOptionGroupsMap(map);
    }
  }, [filteredOptionGroups]);

  // Sets default options for the bath configuration
  //
  // A configuration option is set to the first available option in
  // each of the option groups if these conditions are respected:
  //   - An option is not already configured for the option group
  //   - The option group is marked as required (not required: defaults to undefined)
  //   - The option group is available (in filteredOptionGroupsMap)
  useEffect(() => {
    if (
      filteredOptionGroups &&
      filteredOptionGroups.length > 0 &&
      filteredOptionGroupsMap &&
      !model.configuration
    ) {
      setConfiguration((configuration: IConfiguration) => {
        const defaultOptions = Object.entries(ConfigurationOptions).reduce(
          (defaultOptions, [key, value]) => {
            const optionGroup = filteredOptionGroupsMap?.get(value);
            if (
              !!optionGroup &&
              !filteredOptionGroups.some(
                filteredOptionGroup =>
                  filteredOptionGroup.id === optionGroup.id,
              )
            )
              return { ...defaultOptions, [key]: undefined };

            if (!!configuration[key as keyof IConfiguration])
              return {
                ...defaultOptions,
                [key]: configuration[key as keyof IConfiguration],
              };

            return {
              ...defaultOptions,
              [key]: optionGroup?.options.filter(
                option => option.isRequired,
              )[0],
            };
          },
          {},
        );
        return {
          ...configuration,
          ...defaultOptions,
        };
      });
    }
  }, [filteredOptionGroupsMap, filteredOptionGroups]);

  useEffect(() => {
    setPrice(computeOrderPrice(model, configuration));
  }, [configuration]);

  const getOptionGroupValue = (optionGroup: IOptionGroup) => {
    const fieldName = Object.entries(ConfigurationOptions).find(
      ([_, value]) => value === optionGroup.name,
    )?.[0];
    if (fieldName) {
      return getConfigurationOptionValue(
        configuration[fieldName as keyof IConfiguration] as IOption,
      );
    }
    return "";
  };

  const getOptionGroupPrice = (optionGroup: IOptionGroup) => {
    const fieldName = Object.entries(ConfigurationOptions).find(
      ([_, value]) => value === optionGroup.name,
    )?.[0];
    if (fieldName) {
      return (configuration[fieldName as keyof IConfiguration] as
        | IOption
        | undefined)?.price;
    }
    return 0;
  };

  const toggleOptionalTherapy = (therapy: ITherapy) => {
    const filteredOptionalTherapies =
      configuration.optionalTherapies?.filter(
        (optionalTherapy: ITherapy) => optionalTherapy.id !== therapy.id,
      ) || [];

    const listWasFiltered =
      filteredOptionalTherapies.length !==
      (configuration.optionalTherapies?.length || 0);

    setConfiguration((configuration: IConfiguration) => ({
      ...configuration,
      optionalTherapies: listWasFiltered
        ? filteredOptionalTherapies
        : [
            ...configuration.optionalTherapies,
            {
              ...therapy,
              options: [],
            },
          ],
    }));
  };

  const getOptionGroupTitle = (
    optionGroupName: ConfigurationOptions,
    plural = false,
  ): string => {
    const key = Object.entries(ConfigurationOptions).find(
      ([_, value]) => value === optionGroupName,
    )?.[0];
    if (!key) return optionGroupName.toString();
    return plural
      ? i18n.unsafeT(`configuration_option_title_plural_${key}`)
      : i18n.unsafeT(`configuration_option_title_${key}`);
  };

  useEffect(() => {
    () => {
      isMounted.current = false;
    };
  }, []);

  useLayoutEffect(() => {
    if (openOption && currentPanelRef) {
      if (didStartTimer.current) {
        return;
      }

      didStartTimer.current = true;

      setMaxHeight(currentPanelRef.scrollHeight);

      setOptionOpening(true);
      animatedScrollTo(contentWrapper.current, 0, 700);
      setTimeout(() => {
        if (!isMounted.current) {
          return;
        }
        setMaxHeight(undefined);
        setOptionOpened(true);
      }, 700);
    }
  }, [openOption, currentPanelRef]);

  const handleOptionClick = useCallback((option: ConfigurationOptions) => {
    if (!optionsRef.current) {
      return;
    }

    if (!contentWrapper.current) {
      return;
    }

    setMaxHeight(optionsRef.current.scrollHeight);

    setOpenOption(option);
    setNextOption(option);
  }, []);

  useEffect(() => {
    if (!therapyOverlay || !overlayRef.current) {
      return;
    }

    function handleWindowClick(e: MouseEvent) {
      if (!(e.target instanceof HTMLElement)) {
        setTherapyOverlay(null);
        return;
      }

      if (
        e.target === overlayRef.current ||
        overlayRef.current?.contains(e.target)
      ) {
        return;
      }

      setTherapyOverlay(null);
    }

    function handleResize() {
      setTherapyOverlay(null);
    }

    window.addEventListener("click", handleWindowClick);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("click", handleWindowClick);
      window.removeEventListener("resize", handleResize);
    };
  }, [therapyOverlay]);

  const handleInfoWidgetClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>, therapy: ITherapy) => {
      e.stopPropagation();

      if (!overlayRef.current) {
        return;
      }

      const buttonRect = e.currentTarget.getBoundingClientRect();
      const overlayRect = overlayRef.current.getBoundingClientRect();
      const overlayTop =
        (buttonRect.y - overlayRect.y) / 2 +
        (window.innerHeight / 2 < buttonRect.y ? -200 : 0);

      setTherapyOverlay({
        therapy,
        arrowTop: buttonRect.top - overlayRect.top - overlayTop,
        overlayTop: overlayTop,
      });
    },
    [],
  );

  const handleActionClick = () => {
    if(openOption) {
      sendCustomizeProductEvent(window, configuration)
    }
    

    if (!contentWrapper.current) {
      return;
    }

    if (optionClosing) {
      return;
    }

    if (optionOpening && !optionOpened) {
      return;
    }

    if (!openOption) {
      sendAddToCartEvent(window, model.name)
      navigate(makeLink(ROUTES.order()), {
        state: {
          model: { ...model, configuration },
          collectionName,
        },
      });
    } else {
      setMaxHeight(currentPanelRef?.scrollHeight);

      setOptionClosing(true);
      setNextOption(undefined);
    }
  };

  useEffect(() => {
    if (optionClosing) {
      setMaxHeight(optionsRef.current?.clientHeight);
      animatedScrollTo(contentWrapper.current, 0, 700);
      setTimeout(() => {
        if (!isMounted.current) {
          return;
        }
        setOptionOpening(false);
        setOptionOpened(false);
        setOptionClosing(false);
        setOpenOption(undefined);
        setMaxHeight(undefined);
        setCurrentPanelRef(undefined);
        didStartTimer.current = false;
      }, 700);
    }
  }, [optionClosing]);

  const bathColorDependentOptions: Array<{
    key: keyof IConfiguration;
    optionGroupName: ConfigurationOptions;
    updateCallback: (option: IOption) => void;
  }> = useMemo(
    () => [
      {
        key: "baseColor",
        optionGroupName: ConfigurationOptions.baseColor,
        updateCallback: baseColor =>
          updateChosenOption(
            configuration.bathColor,
            baseColor,
            ConfigurationOptions.baseColor,
          ),
      },
      {
        key: "grabBar",
        optionGroupName: ConfigurationOptions.grabBar,
        updateCallback: grabBar =>
          updateChosenOption(
            configuration.bathColor,
            grabBar,
            ConfigurationOptions.grabBar,
          ),
      },
      {
        key: "skirt",
        optionGroupName: ConfigurationOptions.skirt,
        updateCallback: () => updateChosenSkirtColor(configuration.bathColor),
      },
      {
        key: "control",
        optionGroupName: ConfigurationOptions.control,
        updateCallback: () => updateChosenControlColor(configuration.bathColor),
      },
      {
        key: "ultraVelour",
        optionGroupName: ConfigurationOptions.ultraVelour,
        updateCallback: () => updateUltraVelour(configuration.bathColor),
      },
    ],
    [configuration.bathColor],
  );

  const dispositionDependentOptions: Array<{
    key: keyof IConfiguration;
    optionGroupName: ConfigurationOptions;
    updateCallback: (option: IOption) => void;
  }> = useMemo(
    () => [
      {
        key: "tileFlange",
        optionGroupName: ConfigurationOptions.tileFlange,
        updateCallback: () => updateChosenTileFlange(configuration.disposition),
      },
    ],
    [configuration.disposition],
  );

  // Updates an option's color if it is no longer available
  // The color is available if it doesn't have a required option,
  // or if the required option is currently configured
  useEffect(() => {
    bathColorDependentOptions.forEach(item => {
      const option = configuration[item.key] as IOption;
      const optionGroup = filteredOptionGroupsMap?.get(item.optionGroupName);
      if (!!optionGroup && configuration.bathColor && option) {
        item.updateCallback(option);
      }
    });
  }, [configuration.bathColor, filteredOptionGroupsMap]);

  // Updates an option's color if it is no longer available
  // The color is available if it doesn't have a required option,
  // or if the required option is currently configured
  useEffect(() => {
    dispositionDependentOptions.forEach(item => {
      const option = configuration[item.key] as IOption;
      const optionGroup = filteredOptionGroupsMap?.get(item.optionGroupName);
      if (!!optionGroup && configuration.bathColor && option) {
        item.updateCallback(option);
      }
    });
  }, [configuration.disposition, filteredOptionGroupsMap]);

  // Adds optional therapies to the configuration if any is
  // included with the currently chosen main therapy
  useEffect(() => {
    if (configuration.therapy) {
      const modelTherapies = model.therapies || [];
      const includedTherapies: ITherapy[] = modelTherapies
        .filter(
          (therapy: ITherapy) =>
            therapy.includedWithTherapyId === configuration.therapy.id,
        )
        .map(therapy => {
          // Attach therapy options
          if (!model.configuration) return { ...therapy, options: [] };
          const therapyWasChanged =
            model.configuration.therapy?.id !== configuration.therapy?.id;
          const matchingTherapy = !therapyWasChanged
            ? model.configuration.optionalTherapies?.find(
                t => t.id === therapy.id,
              )
            : undefined;
          return matchingTherapy
            ? { ...therapy, options: matchingTherapy.options || [] }
            : { ...therapy, options: [] };
        });

      setConfiguration((configuration: IConfiguration) => {
        const newOptionalTherapies = includedTherapies
          .concat(configuration.optionalTherapies || [])
          .reduce((unique: ITherapy[], therapy: ITherapy) => {
            return !!unique.some(t => t.id === therapy.id)
              ? unique
              : [...unique, therapy];
          }, []);
        return { ...configuration, optionalTherapies: newOptionalTherapies };
      });
    }
  }, [configuration.therapy, model.therapies]);

  // Removes optionalTherapy options that are no longer available
  // or included with the configured main therapy
  useEffect(() => {
    if (
      configuration.optionalTherapies &&
      configuration.optionalTherapies.length > 0 &&
      model.therapies &&
      model.therapies.length > 0 &&
      !!configuration.therapy &&
      !configuration.therapy.isTMU
    ) {
      configuration.optionalTherapies.forEach((optionalTherapy: ITherapy) => {
        const matchingTherapy = model.therapies.find(
          therapy => therapy.id === optionalTherapy.id,
        );
        const matchingOptions =
          matchingTherapy?.options?.filter(
            option =>
              !option.availableWithTherapyIds ||
              option.availableWithTherapyIds.includes(configuration.therapy.id),
          ) || [];
        if (
          matchingOptions?.length > 0 &&
          optionalTherapy.options?.length === 0
        ) {
          setConfiguration((configuration: IConfiguration) => {
            const newOptionalTherapies = configuration.optionalTherapies.map(
              therapy =>
                therapy.id === optionalTherapy.id
                  ? { ...therapy, options: [matchingOptions[0]] }
                  : therapy,
            );
            return {
              ...configuration,
              optionalTherapies: newOptionalTherapies,
            };
          });
        }
      });
    }
  }, [configuration.therapy, configuration.optionalTherapies, model.therapies]);

  const updateChosenTileFlange = (requiredOption: IOption | undefined) => {
    if (!configuration.tileFlange || !requiredOption) return;

    updateChosenOption(
      requiredOption,
      configuration.tileFlange,
      ConfigurationOptions.tileFlange,
    );
  };

  const updateUltraVelour = (requiredOption: IOption | undefined) => {
    if (!configuration.ultraVelour) return;
    if (configuration.ultraVelour.requiredOption.id !== requiredOption?.id) {
      setConfiguration({
        ...configuration,
        ultraVelour: undefined,
      });
    }
  };

  const updateChosenSkirtColor = (requiredOption: IOption | undefined) => {
    if (!configuration.skirt || !requiredOption) return;
    const skirtType: string =
      skirtTypes.find(type =>
        stringIncludes(configuration.skirt?.description || "", type),
      ) || "";
    if (!!skirtType)
      updateChosenOption(
        requiredOption,
        configuration.skirt,
        ConfigurationOptions.skirt,
        (option: IOption) =>
          stringIncludes(option.description || "", skirtType),
      );
  };

  const updateChosenControlColor = (requiredOption: IOption | undefined) => {
    if (!configuration.control || !requiredOption) return;

    const isPlus = !!configuration.control.description?.includes("PLUS");

    updateChosenOption(
      requiredOption,
      configuration.control,
      ConfigurationOptions.control,
      (option: IOption) =>
        (isPlus && stringIncludes(option.description || "", "PLUS")) ||
        (!isPlus && !stringIncludes(option.description || "", "PLUS")),
    );
  };

  const updateChosenOption = (
    requiredOption: IOption | undefined,
    chosenOption: IOption | undefined,
    optionGroupName: ConfigurationOptions,
    isFullMatch: ((option: IOption) => boolean) | null = null,
  ) => {
    const optionGroup = optionGroups?.find(
      optionGroup => optionGroup.name === optionGroupName,
    );
    let newOption: IOption | undefined = chosenOption;
    if (
      chosenOption &&
      requiredOption &&
      chosenOption.requiredOption != undefined &&
      chosenOption.requiredOption.id !== requiredOption.id
    ) {
      newOption = optionGroup?.options?.find(
        option =>
          option.requiredOption?.id === requiredOption.id &&
          (!isFullMatch || isFullMatch(option)),
      );
    }

    const key = Object.entries(ConfigurationOptions).find(
      ([_, value]) => value === optionGroupName,
    )?.[0];

    if (newOption && key)
      setConfiguration((configuration: IConfiguration) => ({
        ...configuration,
        [key]: newOption,
      }));
  };

  return (
    <>
      <div
        className={C(style.configurationPanel, {
          [style.configurationPanelOverlayed]: !!therapyOverlay,
        })}
      >
        <h2 className={style.configurationTitle}>
          {!nextOption ? (
            <Text t="model_configuration_panel_title" />
          ) : (
            getOptionGroupTitle(nextOption, true)
          )}
        </h2>
        <div ref={overlayRef} className={style.configurationOverlayWrapper}>
          {!!therapyOverlay && (
            <>
              <div
                className={style.configurationOverlay}
                style={{ right: 0, top: therapyOverlay.overlayTop }}
              >
                <div
                  className={style.configurationOverlayArrow}
                  style={{ top: therapyOverlay.arrowTop + 12 }}
                />
                <div className={style.configurationOverlayContent}>
                  <TherapyCard
                    therapy={therapyOverlay.therapy}
                    showCollectionButton={false}
                  />
                </div>
              </div>
              <div className={style.configurationOverlayMobile}>
                <div className={style.configurationOverlayMobileHeader}>
                  <button
                    onClick={() => setTherapyOverlay(null)}
                    className={style.configurationOverlayMobileClose}
                  >
                    <img
                      src={require("../../images/icons/close.svg")}
                      alt="close"
                    />
                  </button>
                </div>
                <div className={style.configurationOverlayMobileContent}>
                  <TherapyCard
                    therapy={therapyOverlay.therapy}
                    showCollectionButton={false}
                    fullWidth
                    noBackground
                  />
                </div>
              </div>
            </>
          )}
        </div>
        <div ref={contentWrapper} className={style.configurationContentWrapper}>
          <div
            style={{ maxHeight }}
            className={C(style.configurationContentWrapper2, {
              [style.configurationContentWrapper2Opening]: optionOpening,
              [style.configurationContentWrapper2Opened]: optionOpened,
              [style.configurationContentWrapper2Closing]: optionClosing,
            })}
          >
            <div
              style={
                optionOpened && !optionClosing
                  ? { maxHeight: 0, overflow: "hidden" }
                  : {}
              }
              className={style.configurationContentWrapper3}
            >
              <div
                ref={optionsRef}
                className={C(style.configurationContent, {
                  [style.configurationContentDisabled]: openOption,
                })}
              >
                <div className={style.configurationOptions}>
                  <ConfigurationOption
                    label={getOptionGroupTitle(ConfigurationOptions.therapies)}
                    value={getChosenTherapiesLabel(configuration)}
                    price={computeTherapiesPrice(configuration, false)}
                    onClick={() =>
                      handleOptionClick(ConfigurationOptions.therapies)
                    }
                  />
                  {filteredOptionGroups
                    .filter(optionGroup =>
                      Object.values(ConfigurationOptions).includes(
                        optionGroup.name,
                      ),
                    )
                    .map(optionGroup => (
                      <ConfigurationOption
                        key={`option-group-${optionGroup.name}`}
                        label={getOptionGroupTitle(optionGroup.name)}
                        value={getOptionGroupValue(optionGroup)}
                        price={getOptionGroupPrice(optionGroup)}
                        onClick={() => handleOptionClick(optionGroup.name)}
                      />
                    ))}
                </div>
              </div>
            </div>
            <div className={style.configurationContentWrapper3}>
              {openOption === ConfigurationOptions.therapies && (
                <div
                  ref={ref => ref && setCurrentPanelRef(ref)}
                  className={style.configurationContent}
                >
                  <TherapyOptionPanel
                    therapies={therapies}
                    configuration={configuration}
                    onInfoWidgetClick={handleInfoWidgetClick}
                    onChooseTherapy={(therapy: ITherapy) => {
                      therapy.isBaseTherapy
                        ? setConfiguration({
                            ...configuration,
                            therapy: { ...therapy, options: [] },
                            optionalTherapies:
                              configuration.optionalTherapies?.map(
                                (therapy: ITherapy) => ({
                                  ...therapy,
                                  options: [],
                                }),
                              ) || [],
                          })
                        : toggleOptionalTherapy(therapy);
                    }}
                    onChooseTherapyOption={(
                      option: ITherapyOption,
                      therapy: ITherapy,
                    ) => {
                      if (
                        therapy.isBaseTherapy &&
                        configuration.therapy?.id === therapy.id
                      ) {
                        setConfiguration((configuration: IConfiguration) => ({
                          ...configuration,
                          therapy: {
                            ...configuration.therapy,
                            options: [
                              ...(configuration.therapy?.options || []),
                              option,
                            ],
                          },
                        }));
                      } else {
                        setConfiguration((configuration: IConfiguration) => ({
                          ...configuration,
                          optionalTherapies: configuration.optionalTherapies.map(
                            t => {
                              if (t.id !== therapy.id) return t;
                              const hasOption = !!t.options?.some(
                                o => o.id === option.id,
                              );
                              return hasOption
                                ? {
                                    ...t,
                                    options: t.options.filter(
                                      o => o.id !== option.id,
                                    ),
                                  }
                                : {
                                    ...t,
                                    options: [...(t.options || []), option],
                                  };
                            },
                          ),
                        }));
                      }
                    }}
                  />
                </div>
              )}
              {openOption === ConfigurationOptions.disposition && (
                <div
                  ref={ref => ref && setCurrentPanelRef(ref)}
                  className={style.configurationContent}
                >
                  <BaseOptionPanel
                    subBannerTitle={i18n.t(
                      "configuration_option_text_title_disposition",
                    )}
                    subBannerText={i18n.t(
                      "configuration_option_text_disposition",
                    )}
                    name="option-disposition"
                    optionGroup={filteredOptionGroupsMap?.get(
                      ConfigurationOptions.disposition,
                    )}
                    banner={configuration.disposition?.banner}
                    chosenOption={configuration.disposition}
                    onChoose={disposition =>
                      setConfiguration((configuration: IConfiguration) => ({
                        ...configuration,
                        disposition,
                      }))
                    }
                  />
                </div>
              )}
              {openOption === ConfigurationOptions.bathColor && (
                <div
                  ref={ref => ref && setCurrentPanelRef(ref)}
                  className={style.configurationContent}
                >
                  <BaseOptionPanel
                    name="option-bath-color"
                    optionGroup={filteredOptionGroupsMap?.get(
                      ConfigurationOptions.bathColor,
                    )}
                    imageCallback={bathColor =>
                      bathColor.color?.image || undefined
                    }
                    chosenOption={configuration.bathColor}
                    onChoose={bathColor => {
                      setConfiguration((configuration: IConfiguration) => ({
                        ...configuration,
                        bathColor,
                      }));
                    }}
                    footnote={i18n.t("configuration_option_footnote_bathColor")}
                  />
                </div>
              )}
              {openOption === ConfigurationOptions.ultraVelour && (
                <div
                  ref={ref => ref && setCurrentPanelRef(ref)}
                  className={style.configurationContent}
                >
                  <BaseOptionPanel
                    name="option-ultra-velour"
                    optionGroup={filteredOptionGroupsMap?.get(
                      ConfigurationOptions.ultraVelour,
                    )}
                    imageCallback={ultraVelour =>
                      ultraVelour.color?.image || undefined
                    }
                    chosenOption={configuration.ultraVelour}
                    isCheckbox
                    isCheckboxChecked={!!configuration.ultraVelour}
                    onChoose={ultraVelour => {
                      setConfiguration((configuration: IConfiguration) => ({
                        ...configuration,
                        ultraVelour: !!configuration.ultraVelour
                          ? undefined
                          : ultraVelour,
                      }));
                    }}
                  />
                </div>
              )}
              {openOption === ConfigurationOptions.wasteOverflow && (
                <div
                  ref={ref => ref && setCurrentPanelRef(ref)}
                  className={style.configurationContent}
                >
                  <TypeConditionalPanel
                    type={
                      filteredOptionGroupsMap?.get(
                        ConfigurationOptions.wasteOverflow,
                      )?.isRequired
                        ? BaseOptionPanel
                        : OptionalOptionPanel
                    }
                    name="option-waste-overflow"
                    subBannerTitle={
                      !!configuration.wasteOverflow
                        ? i18n.t("configuration_option_text_wasteOverflow")
                        : undefined
                    }
                    optionGroup={filteredOptionGroupsMap?.get(
                      ConfigurationOptions.wasteOverflow,
                    )}
                    banner={
                      !!configuration.wasteOverflow
                        ? configuration.wasteOverflow.banner
                        : model.options.find(
                            option =>
                              option.optionGroup.name ===
                              ConfigurationOptions.wasteOverflow,
                          )?.banner
                    }
                    imageCallback={(option: IOption) => option.color?.image}
                    chosenOption={configuration.wasteOverflow}
                    onChoose={(wasteOverflow: IOption) =>
                      setConfiguration((configuration: IConfiguration) => ({
                        ...configuration,
                        wasteOverflow,
                      }))
                    }
                    optionSets={wasteOverflowOptionSets}
                    label={i18n.t(
                      "configuration_option_checkbox_wasteOverflow",
                    )}
                    isOptionDisplayed={!!configuration.wasteOverflow}
                    onCheckToggle={(
                      checked: boolean,
                      defaultOption: IOption | undefined,
                    ) =>
                      setConfiguration((configuration: IConfiguration) => ({
                        ...configuration,
                        wasteOverflow: checked ? defaultOption : undefined,
                      }))
                    }
                  />
                </div>
              )}
              {openOption === ConfigurationOptions.feet && (
                <div
                  ref={ref => ref && setCurrentPanelRef(ref)}
                  className={style.configurationContent}
                >
                  <BaseOptionPanel
                    name="option-feet"
                    optionGroup={filteredOptionGroupsMap?.get(
                      ConfigurationOptions.feet,
                    )}
                    imageCallback={feet => feet.color?.image || undefined}
                    banner={configuration.feet?.banner}
                    chosenOption={configuration.feet}
                    onChoose={feet =>
                      setConfiguration((configuration: IConfiguration) => ({
                        ...configuration,
                        feet,
                      }))
                    }
                  />
                </div>
              )}
              {openOption === ConfigurationOptions.deckColor && (
                <div
                  ref={ref => ref && setCurrentPanelRef(ref)}
                  className={style.configurationContent}
                >
                  <BaseOptionPanel
                    name="option-deck-color"
                    optionGroup={filteredOptionGroupsMap?.get(
                      ConfigurationOptions.deckColor,
                    )}
                    imageCallback={deckColor =>
                      deckColor.color?.image || undefined
                    }
                    banner={configuration.deckColor?.banner}
                    chosenOption={configuration.deckColor}
                    onChoose={(deckColor: IOption) =>
                      setConfiguration((configuration: IConfiguration) => ({
                        ...configuration,
                        deckColor,
                      }))
                    }
                  />
                </div>
              )}
              {openOption === ConfigurationOptions.cushion && (
                <div
                  ref={ref => ref && setCurrentPanelRef(ref)}
                  className={style.configurationContent}
                >
                  <BaseOptionPanel
                    name="option-cushion"
                    optionGroup={filteredOptionGroupsMap?.get(
                      ConfigurationOptions.cushion,
                    )}
                    imageCallback={cushion => cushion.color?.image || undefined}
                    banner={configuration.cushion?.banner}
                    chosenOption={configuration.cushion}
                    onChoose={(cushion: IOption) =>
                      setConfiguration((configuration: IConfiguration) => ({
                        ...configuration,
                        cushion,
                      }))
                    }
                  />
                </div>
              )}
              {openOption === ConfigurationOptions.control && (
                <div
                  ref={ref => ref && setCurrentPanelRef(ref)}
                  className={style.configurationContent}
                >
                  <BaseOptionPanel
                    name="option-control"
                    optionGroup={filteredOptionGroupsMap?.get(
                      ConfigurationOptions.control,
                    )}
                    imageCallback={control => control.banner || undefined}
                    chosenOption={configuration.control}
                    isBigRadio
                    onChoose={control =>
                      setConfiguration((configuration: IConfiguration) => ({
                        ...configuration,
                        control,
                      }))
                    }
                  />
                </div>
              )}
              {openOption === ConfigurationOptions.buTouch && (
                <div
                  ref={ref => ref && setCurrentPanelRef(ref)}
                  className={style.configurationContent}
                >
                  <BaseOptionPanel
                    name="option-bu-touch"
                    subBannerText={i18n.t("configuration_option_text_buTouch")}
                    optionGroup={filteredOptionGroupsMap?.get(
                      ConfigurationOptions.buTouch,
                    )}
                    banner={
                      model.options.find(
                        option =>
                          option.optionGroup.name ===
                          ConfigurationOptions.buTouch,
                      )?.banner
                    }
                    chosenOption={configuration.buTouch}
                    onChoose={buTouch => {
                      const buTouchOptionGroup = filteredOptionGroupsMap?.get(
                        ConfigurationOptions.buTouch,
                      );
                      const isRequired =
                        buTouchOptionGroup?.options.some(
                          option => option.isRequired,
                        ) || false;

                      if (!isRequired)
                        setConfiguration((configuration: IConfiguration) => ({
                          ...configuration,
                          buTouch: !!configuration.buTouch
                            ? undefined
                            : buTouch,
                        }));
                    }}
                    isCheckbox
                    isCheckboxChecked={!!configuration.buTouch}
                  />
                </div>
              )}
              {openOption === ConfigurationOptions.airIntake && (
                <div
                  ref={ref => ref && setCurrentPanelRef(ref)}
                  className={style.configurationContent}
                >
                  <OptionalOptionPanel
                    name="option-airIntake"
                    optionGroup={filteredOptionGroupsMap?.get(
                      ConfigurationOptions.airIntake,
                    )}
                    chosenOption={configuration.airIntake}
                    onChoose={airIntake =>
                      setConfiguration((configuration: IConfiguration) => ({
                        ...configuration,
                        airIntake,
                      }))
                    }
                    beforeBannerText={i18n.t(
                      "configuration_option_text_airIntake",
                    )}
                    label={i18n.t("configuration_option_checkbox_airIntake")}
                    imageCallback={(option: IOption) => option.color?.image}
                    isOptionDisplayed={!!configuration.airIntake}
                    onCheckToggle={(
                      checked: boolean,
                      defaultOption: IOption | undefined,
                    ) =>
                      setConfiguration((configuration: IConfiguration) => ({
                        ...configuration,
                        airIntake: checked ? defaultOption : undefined,
                      }))
                    }
                  />
                </div>
              )}
              {openOption === ConfigurationOptions.tileFlange && (
                <div
                  ref={ref => ref && setCurrentPanelRef(ref)}
                  className={style.configurationContent}
                >
                  <TypeConditionalPanel
                    type={
                      filteredOptionGroupsMap?.get(
                        ConfigurationOptions.tileFlange,
                      )?.isRequired
                        ? BaseOptionPanel
                        : OptionalOptionPanel
                    }
                    name="option-tile-flange"
                    optionGroup={filteredOptionGroupsMap?.get(
                      ConfigurationOptions.tileFlange,
                    )}
                    banner={
                      !!configuration.tileFlange
                        ? configuration.tileFlange.banner
                        : model.options.find(
                            option =>
                              option.optionGroup.name ===
                              ConfigurationOptions.tileFlange,
                          )?.banner
                    }
                    label={i18n.t("configuration_option_checkbox_tileFlange")}
                    imageCallback={(option: IOption) => option.color?.image}
                    chosenOption={configuration.tileFlange}
                    onChoose={(tileFlange: IOption) =>
                      setConfiguration((configuration: IConfiguration) => ({
                        ...configuration,
                        tileFlange,
                      }))
                    }
                    isOptionDisplayed={!!configuration.tileFlange}
                    onCheckToggle={(
                      checked: boolean,
                      defaultOption: IOption | undefined,
                    ) =>
                      setConfiguration((configuration: IConfiguration) => ({
                        ...configuration,
                        tileFlange: checked ? defaultOption : undefined,
                      }))
                    }
                  />
                </div>
              )}
              {openOption === ConfigurationOptions.armrest && (
                <div
                  ref={ref => ref && setCurrentPanelRef(ref)}
                  className={style.configurationContent}
                >
                  <OptionalOptionPanel
                    subBannerTitle={
                      !!configuration.armrest
                        ? i18n.t("configuration_option_text_armrest")
                        : undefined
                    }
                    name="option-armrest"
                    optionGroup={filteredOptionGroupsMap?.get(
                      ConfigurationOptions.armrest,
                    )}
                    banner={
                      !!configuration.armrest
                        ? configuration.armrest.banner
                        : model.options.find(
                            option =>
                              option.optionGroup.name ===
                              ConfigurationOptions.armrest,
                          )?.banner
                    }
                    imageCallback={(option: IOption) => option.color?.image}
                    chosenOption={configuration.armrest}
                    onChoose={armrest =>
                      setConfiguration((configuration: IConfiguration) => ({
                        ...configuration,
                        armrest,
                      }))
                    }
                    label={i18n.t("configuration_option_checkbox_armrest")}
                    isOptionDisplayed={!!configuration.armrest}
                    onCheckToggle={(
                      checked: boolean,
                      defaultOption: IOption | undefined,
                    ) =>
                      setConfiguration((configuration: IConfiguration) => ({
                        ...configuration,
                        armrest: checked ? defaultOption : undefined,
                      }))
                    }
                  />
                </div>
              )}
              {openOption === ConfigurationOptions.towelBar && (
                <div
                  ref={ref => ref && setCurrentPanelRef(ref)}
                  className={style.configurationContent}
                >
                  <OptionalOptionPanel
                    subBannerTitle={
                      !!configuration.towelBar
                        ? i18n.t("configuration_option_text_towelBar")
                        : undefined
                    }
                    name="option-towel-bar"
                    optionGroup={filteredOptionGroupsMap?.get(
                      ConfigurationOptions.towelBar,
                    )}
                    banner={
                      !!configuration.towelBar
                        ? configuration.towelBar.banner
                        : model.options.find(
                            option =>
                              option.optionGroup.name ===
                              ConfigurationOptions.towelBar,
                          )?.banner
                    }
                    imageCallback={(option: IOption) => option.color?.image}
                    chosenOption={configuration.towelBar}
                    onChoose={towelBar =>
                      setConfiguration((configuration: IConfiguration) => ({
                        ...configuration,
                        towelBar,
                      }))
                    }
                    optionSets={towelBarOptionSets}
                    label={i18n.t("configuration_option_checkbox_towelBar")}
                    isOptionDisplayed={!!configuration.towelBar}
                    onCheckToggle={(
                      checked: boolean,
                      defaultOption: IOption | undefined,
                    ) =>
                      setConfiguration((configuration: IConfiguration) => ({
                        ...configuration,
                        towelBar: checked ? defaultOption : undefined,
                      }))
                    }
                  />
                </div>
              )}
              {openOption === ConfigurationOptions.skirt && (
                <div
                  ref={ref => ref && setCurrentPanelRef(ref)}
                  className={style.configurationContent}
                >
                  <OptionalOptionPanel
                    subBannerTitle={
                      !!configuration.towelBar
                        ? i18n.t("configuration_option_text_skirt")
                        : undefined
                    }
                    name="option-skirt"
                    optionGroup={filteredOptionGroupsMap?.get(
                      ConfigurationOptions.skirt,
                    )}
                    banner={
                      !!configuration.skirt
                        ? configuration.skirt.banner
                        : model.options.find(
                            option =>
                              option.optionGroup.name ===
                              ConfigurationOptions.skirt,
                          )?.banner
                    }
                    imageCallback={(option: IOption) => option.color?.image}
                    chosenOption={configuration.skirt}
                    onChoose={skirt => {
                      setConfiguration((configuration: IConfiguration) => ({
                        ...configuration,
                        skirt,
                      }));
                    }}
                    label={i18n.t("configuration_option_checkbox_skirt")}
                    isOptionDisplayed={!!configuration.skirt}
                    onCheckToggle={(
                      checked: boolean,
                      defaultOption: IOption | undefined,
                    ) => {
                      setConfiguration((configuration: IConfiguration) => ({
                        ...configuration,
                        skirt: checked ? defaultOption : undefined,
                      }));
                    }}
                  />
                </div>
              )}
              {openOption === ConfigurationOptions.deckType && (
                <div
                  ref={ref => ref && setCurrentPanelRef(ref)}
                  className={style.configurationContent}
                >
                  <BaseOptionPanel
                    name="option-deck-type"
                    optionGroup={filteredOptionGroupsMap?.get(
                      ConfigurationOptions.deckType,
                    )}
                    chosenOption={configuration.deckType}
                    onChoose={deckType =>
                      setConfiguration((configuration: IConfiguration) => ({
                        ...configuration,
                        deckType,
                      }))
                    }
                    imageCallback={(deckType: IOption) =>
                      deckType.banner || undefined
                    }
                    isBigRadio
                  />
                </div>
              )}
              {openOption === ConfigurationOptions.grabBar && (
                <div
                  ref={ref => ref && setCurrentPanelRef(ref)}
                  className={style.configurationContent}
                >
                  <BaseOptionPanel
                    name="option-grab-bar"
                    optionGroup={filteredOptionGroupsMap?.get(
                      ConfigurationOptions.grabBar,
                    )}
                    chosenOption={configuration.grabBar}
                    onChoose={grabBar =>
                      setConfiguration((configuration: IConfiguration) => ({
                        ...configuration,
                        grabBar,
                      }))
                    }
                    banner={configuration.grabBar?.banner}
                    imageCallback={(grabBar: IOption) =>
                      grabBar.color?.image || undefined
                    }
                  />
                </div>
              )}
              {openOption === ConfigurationOptions.baseColor && (
                <div
                  ref={ref => ref && setCurrentPanelRef(ref)}
                  className={style.configurationContent}
                >
                  <BaseOptionPanel
                    name="option-base-color"
                    optionGroup={filteredOptionGroupsMap?.get(
                      ConfigurationOptions.baseColor,
                    )}
                    chosenOption={configuration.baseColor}
                    onChoose={baseColor =>
                      setConfiguration((configuration: IConfiguration) => ({
                        ...configuration,
                        baseColor,
                      }))
                    }
                    banner={configuration.baseColor?.banner}
                    imageCallback={(baseColor: IOption) =>
                      baseColor.color?.image || undefined
                    }
                  />
                </div>
              )}
              {openOption === ConfigurationOptions.islandTubDrain && (
                <div
                  ref={ref => ref && setCurrentPanelRef(ref)}
                  className={style.configurationContent}
                >
                  <TypeConditionalPanel
                    beforeBannerText={i18n.t(
                      "configuration_option_text_islandTubDrain",
                    )}
                    type={
                      filteredOptionGroupsMap?.get(
                        ConfigurationOptions.islandTubDrain,
                      )?.isRequired
                        ? BaseOptionPanel
                        : OptionalOptionPanel
                    }
                    name="option-island-tub-drain"
                    optionGroup={filteredOptionGroupsMap?.get(
                      ConfigurationOptions.islandTubDrain,
                    )}
                    chosenOption={configuration.islandTubDrain}
                    onChoose={(islandTubDrain: IOption) =>
                      setConfiguration((configuration: IConfiguration) => ({
                        ...configuration,
                        islandTubDrain,
                      }))
                    }
                    banner={configuration.islandTubDrain?.banner}
                    imageCallback={(islandTubDrain: IOption) =>
                      islandTubDrain.color?.image || undefined
                    }
                    label={i18n.t(
                      "configuration_option_checkbox_islandTubDrain",
                    )}
                    isOptionDisplayed={!!configuration.islandTubDrain}
                    onCheckToggle={(
                      checked: boolean,
                      defaultOption: IOption | undefined,
                    ) =>
                      setConfiguration((configuration: IConfiguration) => ({
                        ...configuration,
                        islandTubDrain: checked ? defaultOption : undefined,
                      }))
                    }
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        className={C(style.configurationAction, {
          [style.configurationPanelOverlayed]: !!therapyOverlay,
        })}
      >
        <ConfigurationActionSection
          onClick={handleActionClick}
          label={
            !!nextOption
              ? i18n.t("model_configuration_option_submit_button")
              : i18n.t("model_configuration_submit_button")
          }
          secondaryLabel={!nextOption ? `$ ${price}` : undefined}
        />
      </div>
    </>
  );
};

export default ConfigurationPanel;
