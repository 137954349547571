import React from "react";
import C from "classnames";

import style from "./index.module.styl";

interface IProps {
  label: string;
  secondaryLabel?: string;
  onClick: () => void;
}

const ConfigurationActionSection = ({
  label,
  secondaryLabel,
  onClick,
}: IProps) => (
  <div className={style.configurationActionSection}>
    <button
      type="button"
      onClick={() => onClick()}
      className={C(
        `btn btn--blush btn--animate-outline ${style.configurationActionButton}`,
        { [style.spaceBetween]: !!secondaryLabel },
      )}
    >
      <span
        className={C(style.configurationActionLabel, {
          [style.uppercase]: !secondaryLabel,
        })}
      >
        {label}
      </span>
      {!!secondaryLabel && <span>{secondaryLabel}</span>}
    </button>
  </div>
);

export default ConfigurationActionSection;
