import React from "react";

import { Carousel as ReactCarousel } from "react-responsive-carousel";
import C from "classnames";
import Img from "gatsby-image";

import "./index.styl";
import "react-responsive-carousel/lib/styles/carousel.min.css";

interface ICarouselProps {
  readonly images: any[];
  readonly dotsTop?: boolean;
}

export default function Carousel({ images, dotsTop = false }: ICarouselProps) {
  return (
    <div
      className={C("carousel-block", { "carousel-block--dots-top": dotsTop })}
    >
      {images.length === 1 && (
        <Img
          fluid={images[0]}
          alt="single image carousel"
          className="carousel-block__slide__image"
        />
      )}
      {images.length > 1 && (
        <ReactCarousel
          showThumbs={false}
          showArrows={false}
          showStatus={false}
          infiniteLoop
          autoPlay
          swipeable
          className="carousel-block__content"
        >
          {images.map((image, i) => (
            <div key={i} className="carousel-block__slide">
              <Img
                fluid={image}
                alt={`carousel: ${i}`}
                className="carousel-block__slide__image"
              />
              {/* <img
                src={image.src}
                srcSet={image.srcSet}
                alt={`carousel: ${i}`}
                className="carousel-block__slide__image"
              /> */}
            </div>
          ))}
        </ReactCarousel>
      )}
    </div>
  );
}
