import React, { useEffect } from "react";
import { graphql } from "gatsby";
import page from "..";

import style from "./index.module.styl";
import { useGlobalStore } from "../../../store";
import GeneralLayout from "../../layouts/general";
import CenterColumn from "../../centerColumn";
import Header from "../../header";
import Carousel from "../../carousel";
import SVG from "react-inlinesvg";
import { IModel, IOption, IOptionGroup } from "shared/lib/models/model";
import { ITherapy, ITherapyOption } from "shared/lib/models/therapy";
import { IInstallationTypeMapping } from "shared/lib/models/installationTypeMapping";
import { IFluidImage } from "../../../common/gatsby";
import { IInstallationType } from "shared/lib/models/installationType";
import ConfigurationPanel from "../../../components/configurationPanel";
import { ICollection } from "shared/lib/models/collection";
import { ROUTES } from "../../../common/routes.js";
import SEO from "../../seo";
import collection from "../collection";

type ModelPageCollection = Pick<
  ICollection,
  "name" | "description" | "descriptionFr"
>;

type ModelPageInstallationType = Omit<
  IInstallationType,
  "installationTypeMapping"
> & {
  readonly installationTypeMapping: number;
};

type ModelPageModel = Omit<IModel, "slideshow" | "installation_type"> & {
  readonly slideshow: Array<{
    readonly image: IFluidImage;
  }>;
  readonly installation_type: ModelPageInstallationType;
  readonly therapies: Array<ITherapy>;
};

interface IModelPageProps {
  readonly data: {
    readonly strapiCollections: ModelPageCollection;
    readonly strapiModels: ModelPageModel;
    readonly allStrapiOptions: {
      readonly nodes: IOption[];
    };
    readonly allStrapiOptionGroups: {
      readonly nodes: IOptionGroup[];
    };
    readonly allStrapiInstallationTypeMappings: {
      readonly nodes: IInstallationTypeMapping[];
    };
    readonly allStrapiTherapies: {
      readonly nodes: ITherapy[];
    };
    readonly allStrapiTherapyOptions: {
      readonly nodes: ITherapyOption[];
    };
  };
  readonly location: {
    state: {
      model: IModel;
    };
  };
}

const Model = (props: IModelPageProps) => {
  const { i18n, breadcrumbs } = useGlobalStore();

  const model = !!props.location?.state?.model
    ? props.location.state.model
    : props.data?.strapiModels;

  useEffect(() => {
    window.history.pushState(null, "");
  }, []);

  const installationTypeId = model?.installationType?.installationTypeMapping;
  const installationType = props.data?.allStrapiInstallationTypeMappings.nodes.find(
    x => x.id === installationTypeId,
  );

  const options: Array<IOption> = props.data?.allStrapiOptions.nodes;
  const optionGroups: Array<IOptionGroup> =
    props.data?.allStrapiOptionGroups.nodes;
  model.options = options
    .filter(option =>
      model.model_options.some(
        model_option => model_option.option === option.id,
      ),
    )
    .map(option => {
      const optionGroup = optionGroups.find(
        optionGroup => optionGroup.id === option.optionGroup.id,
      );
      const modelOption = model.model_options.find(
        model_option => model_option.option === option.id,
      );
      const availableWithTherapyIds = !!optionGroup.availableWithTherapies
        ? optionGroup.availableWithTherapies.map(therapy => therapy.id)
        : [];
      return option
        ? {
            id: modelOption.option,
            isRequired: modelOption.isRequired,
            price: option.price,
            optionId: option.optionId,
            optionGroup: {
              id: optionGroup.id,
              name: option.optionGroup.name,
              availableWithTherapyIds,
            },
            banner: option.banner,
            color: option.color,
            description: option.description,
            requiredOption: option.requiredOption,
          }
        : undefined;
    });

  const therapyOptions: Array<ITherapyOption> =
    props.data?.allStrapiTherapyOptions.nodes;
  const therapies: Array<ITherapy> = props.data?.allStrapiTherapies.nodes;
  model.therapies = model.therapies.map(therapy => {
    const matchedTherapy = therapies.find(t => t.id === therapy.id);
    const options = therapyOptions
      .filter(
        option =>
          matchedTherapy?.options
            ?.map(option => option.id)
            ?.includes(option.id) &&
          !!model.modelTherapyOptions?.some(
            modelTherapyOption =>
              modelTherapyOption.therapyOption === option.id,
          ),
      )
      .map(option => ({
        id: option.id,
        name: option.name,
        price: option.price,
        availableWithTherapyIds:
          (option as any).availableWithTherapies?.map(
            (therapy: ITherapy) => therapy.id,
          ) || [],
      }));
    return {
      ...therapy,
      ...matchedTherapy,
      includedWithTherapyId: (matchedTherapy as any)?.includedWithTherapy?.id,
      options,
    };
  });

  const pageTitle = `${i18n.t("meta_title_prefix")} - ${model.name}`;
  const modelImageUrl = !!model.image
    ? model.image.childImageSharp.fluid.src
    : !!model.slideshow && model.slideshow.length
    ? model.slideshow[0].image.childImageSharp.fluid.src
    : undefined;
  const collectionDescription = i18n.tObj(
    props.data.strapiCollections.description,
    props.data.strapiCollections.descriptionFr,
  );
  return (
    <GeneralLayout breadcrumbs={breadcrumbs}>
      <SEO
        path={ROUTES.model(collection.name, model.name)}
        title={pageTitle}
        description={collectionDescription}
        imageUrl={modelImageUrl}
      />
      <CenterColumn>
        <Header titleMobile={model.name} titleDesktop={model.name} />
      </CenterColumn>
      <div className={style.model}>
        <div className={style.modelLeft}>
          <div style={{ paddingTop: "50%", width: 0 }} />
          <div style={{ paddingTop: "70vh", width: 0 }} />
        </div>
        <div className={style.modelRight}>
          <div className={style.modelSideBySide}>
            <div className={style.modelSideBySideLeft}>
              <div className={style.modelCarousel}>
                {!!model.slideshow && (
                  <Carousel
                    dotsTop
                    images={[model.slideshow[0].image?.childImageSharp?.fluid]}
                  />
                )}
              </div>
              <div className={style.modelSpecificationsWrapper}>
                <input
                  onFocus={e => (e.currentTarget.checked = true)}
                  onClick={e =>
                    (e.currentTarget.checked = !e.currentTarget.checked)
                  }
                  onBlur={e => (e.currentTarget.checked = false)}
                  id="specifications-toggle"
                  className={style.modelSpecificationsToggle}
                  type="checkbox"
                />
                <div className={style.modelSpecifications}>
                  <label
                    htmlFor="specifications-toggle"
                    className={style.modelSpecificationsHeader}
                  >
                    <div className={style.modelSpecificationsHeaderLeft}>
                      <span>{i18n.t("model_specifications_title")}</span>
                      <div className={style.modelSpecificationsMoreInfo}>
                        <SVG
                          src={require("../../../images/icons/more-info.svg")}
                        />
                      </div>
                    </div>
                    <div className={style.modelSpecificationsHeaderRight}>
                      <SVG src={require("../../../images/icons/close.svg")} />
                    </div>
                  </label>
                  {!!installationType && (
                    <div className={style.modelSpecificationsContent}>
                      <table>
                        <tbody>
                          <tr>
                            <td>{i18n.t("model_specifications_size")}</td>
                            <td className={style.modelSpeicificationsMeasures}>
                              <span>
                                <span>{i18n.t("specifications_length")}</span>
                                <span>{`${model.length}"`}</span>
                              </span>
                              <span>
                                <span>{i18n.t("specifications_width")}</span>
                                <span>{`${model.width}"`}</span>
                              </span>
                              <span>
                                <span>{i18n.t("specifications_height")}</span>
                                <span>{`${model.height}"`}</span>
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {i18n.t(
                                "model_specifications_installaltion_type",
                              )}
                            </td>
                            <td>
                              {i18n.tObj(
                                installationType.title,
                                installationType.title_fr,
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>{i18n.t("model_specifications_bathers")}</td>
                            <td>{model.numberOfBathers}</td>
                          </tr>
                          <tr>
                            <td>{i18n.t("model_specifications_us_gallons")}</td>
                            <td>{model.usGallonsCapacity}</td>
                          </tr>
                          <tr>
                            <td>
                              {i18n.t("model_specifications_imperial_gallons")}
                            </td>
                            <td>{model.imperialGallonsCapacity}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className={style.modelSideBySideRight}>
              <ConfigurationPanel
                model={model}
                collectionName={props.data.strapiCollections.name}
              />
            </div>
          </div>
        </div>
      </div>
    </GeneralLayout>
  );
};

export default page(Model);

export const query = graphql`
  query CollectionModelTemplate($collectionId: Int, $modelId: Int) {
    strapiCollections(strapiId: { eq: $collectionId }) {
      name
      description
      descriptionFr: description_fr
    }
    strapiModels(strapiId: { eq: $modelId }) {
      id: strapiId
      name
      tubPrice
      tmuPrice
      width
      height
      length
      usGallonsCapacity
      imperialGallonsCapacity
      numberOfBathers
      model_options {
        option
        isRequired
      }
      installationType: installation_type {
        installationTypeMapping: installation_type_mapping
      }
      modelTherapyOptions: model_therapy_options {
        therapyOption: therapy_option
      }
      therapies {
        id
        name
        category
        price
        isBaseTherapy
        isTMU
        categoryDescription: category_description
        shortDescription: short_description
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      slideshow {
        image {
          childImageSharp {
            fluid(maxWidth: 2000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    allStrapiInstallationTypeMappings {
      nodes {
        id: strapiId
        title
        title_fr
      }
    }
    allStrapiOptions(
      filter: { model_options: { elemMatch: { model: { eq: $modelId } } } }
      sort: { fields: [option_group___priority, price], order: ASC }
    ) {
      nodes {
        id: strapiId
        optionId
        price
        description
        banner {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
          extension
          publicURL
        }
        color {
          id
          name
          image {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        optionGroup: option_group {
          id
          priority
          name
        }
        requiredOption: required_option {
          id
          optionId
        }
      }
    }
    allStrapiOptionGroups {
      nodes {
        id: strapiId
        availableWithTherapies: available_with_therapies {
          id
        }
      }
    }
    allStrapiTherapies {
      nodes {
        id: strapiId
        options: therapy_options {
          id
        }
        includedWithTherapy: included_with_therapy {
          id
        }
      }
    }
    allStrapiTherapyOptions {
      nodes {
        id: strapiId
        name
        price
        availableWithTherapies: available_with_therapies {
          id
        }
      }
    }
  }
`;
