import React from "react";
import Img from "gatsby-image";

import style from "./index.module.styl";
import { useGlobalStore } from "../../store";
import { ITherapy } from "../../models/therapy";

interface ITherapyTitleImageProps {
  therapy: ITherapy;
  className?: string;
  imageClassName?: string;
  titleClassName?: string;
}

const TherapyTitleImage = ({
  therapy,
  className,
  imageClassName,
  titleClassName,
}: ITherapyTitleImageProps) => {
  const { i18n } = useGlobalStore();

  return (
    <div className={`${style.therapyTitleImage} ${className || ""}`}>
      <h5 className={`${style.therapyTitle} ${titleClassName || ""}`}>
        {i18n.tObj(therapy.category, therapy.categoryFr)}
      </h5>
      {!!therapy.image && (
        <Img
          fluid={therapy.image.childImageSharp.fluid}
          alt={i18n.tObj(therapy.category, therapy.categoryFr)}
          className={`${style.therapyImage} ${imageClassName || ""}`}
        />
      )}
    </div>
  );
};

export default TherapyTitleImage;
