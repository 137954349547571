import React, { useState, useEffect } from "react";
import C from "classnames";
import Img from "gatsby-image";

import {
  IOption,
  IOptionGroup,
  IColor,
  IOptionSet,
} from "shared/lib/models/model";

import ConfigurationRadio from "../configurationRadio";
import SVG from "react-inlinesvg";
import Radio from "../forms/radio";

import style from "./index.module.styl";
import { useGlobalStore } from "../../store";

interface IBaseOptionPanelProps {
  optionGroup: IOptionGroup | undefined;
  name: string;
  beforeBannerText?: string;
  subBannerTitle?: string;
  subBannerText?: string;
  subBannerContent?: React.ReactNode;
  imageCallback?: (option: IOption) => any;
  banner?: any;
  chosenOption?: IOption;
  onChoose: (option: IOption) => void;
  footnote?: string;
  isCheckbox?: boolean;
  isCheckboxChecked?: boolean;
  optionsDisplayed?: boolean;
  tileFlangesToInclude?: IOption[];
  isBigRadio?: boolean;
  optionSets?: Array<IOptionSet>;
}

const BaseOptionPanel = ({
  optionGroup,
  name,
  beforeBannerText,
  subBannerTitle,
  subBannerText,
  subBannerContent,
  imageCallback,
  banner,
  chosenOption,
  onChoose,
  footnote,
  isCheckbox,
  isCheckboxChecked,
  optionsDisplayed = true,
  isBigRadio,
  optionSets,
}: IBaseOptionPanelProps) => {
  const {
    i18n,
    optionHelpers: { getConfigurationOptionValue },
  } = useGlobalStore();

  let optionGroupOptions = optionGroup?.options || [];

  if (
    !!chosenOption &&
    !optionGroup?.options.some(option => option.id === chosenOption.id)
  ) {
    onChoose(optionGroupOptions[0]);
  }

  const filteredOptionSets =
    optionSets?.filter(
      optionSet =>
        !!optionGroup?.options?.find(option =>
          option.description?.includes(optionSet.value),
        ),
    ) || [];

  const [chosenOptionSet, setChosenOptionSet] = useState<
    IOptionSet | undefined
  >(
    filteredOptionSets
      ? chosenOption
        ? filteredOptionSets.find(optionSet =>
            chosenOption.description?.includes(optionSet.value),
          ) || filteredOptionSets[0]
        : filteredOptionSets[0]
      : undefined,
  );
  const [options, setOptions] = useState<IOption[]>(optionGroupOptions || []);

  useEffect(() => {
    if (optionsDisplayed && !!chosenOptionSet) {
      const newOptions =
        optionGroup?.options?.filter(option =>
          option.description?.includes(chosenOptionSet?.value || ""),
        ) || [];
      setOptions(newOptions);
      if (
        !chosenOption ||
        (newOptions.length > 0 &&
          !newOptions.find(option => option.id === chosenOption?.id))
      ) {
        onChoose(newOptions[0]);
      }
    }
  }, [chosenOptionSet, chosenOption, optionGroup?.options, optionsDisplayed]);

  return (
    <>
      {!!beforeBannerText && (
        <p
          className={C(
            style.configurationSubBannerText,
            style.configurationSubBannerContent,
          )}
          dangerouslySetInnerHTML={{ __html: beforeBannerText }}
        ></p>
      )}
      {!!banner && (
        <div className={style.configurationContentBanner}>
          {banner.extension === "svg" && (
            <SVG
              imgStyle={{ objectFit: "contain" }}
              src={banner.publicURL}
              alt={`model ${name}`}
              className={style.configurationContentBannerSvg}
            />
          )}
          {banner.extension !== "svg" && (
            <Img
              fluid={banner.childImageSharp?.fluid}
              alt={`model ${name}`}
              imgStyle={{ objectFit: "contain" }}
              className={style.configurationContentBannerImage}
            />
          )}
        </div>
      )}
      {!!subBannerContent && <>{subBannerContent}</>}
      {!!filteredOptionSets &&
        filteredOptionSets.length > 1 &&
        optionsDisplayed &&
        filteredOptionSets.map(optionSet => (
          <label
            className={style.configurationOptionSetContainer}
            tabIndex={0}
            role={"button"}
            onClick={() => setChosenOptionSet(optionSet)}
            onKeyPress={() => setChosenOptionSet(optionSet)}
          >
            <Radio
              name="option-set"
              tabIndex={-1}
              checked={chosenOptionSet?.value === optionSet?.value}
              onClick={() => setChosenOptionSet(optionSet)}
            />
            <span className={style.configurationOptionSetLabel}>
              {i18n.unsafeT(optionSet.labelKey)}
            </span>
          </label>
        ))}
      {!!subBannerTitle && (
        <p
          className={C(
            style.configurationSubBannerTitle,
            style.configurationSubBannerContent,
          )}
        >
          {subBannerTitle}
        </p>
      )}
      {!!subBannerText && (
        <p
          className={C(
            style.configurationSubBannerText,
            style.configurationSubBannerContent,
          )}
        >
          {subBannerText}
        </p>
      )}
      {optionsDisplayed &&
        options.map((option: IOption) => {
          return (
            <ConfigurationRadio
              key={`${name}-${option.id}`}
              name={name}
              label={getConfigurationOptionValue(option)}
              price={option.price}
              image={
                !!imageCallback ? imageCallback(option) || undefined : undefined
              }
              checked={chosenOption?.id === option.id}
              onClick={() => onChoose(option)}
              isCheckbox={isCheckbox}
              isCheckboxChecked={isCheckboxChecked}
              isBigImage={isBigRadio}
              id={`${name}-${option.id}`}
            />
          );
        })}
      {!!footnote && (
        <p className={style.configurationContentFootnote}>{footnote}</p>
      )}
    </>
  );
};

export default BaseOptionPanel;
